import { DocumentNode, print } from "graphql";
import { z } from "zod";
import { errorToString, sleep } from "..";
import { checkGraphqlQuery } from "./checkGraphqlQuery";
import { isQueryResponseOfType } from "./isQueryResponseOfType";
import { PrismaClient } from "@openqlabs/drm-db";

import {
  NUMBER_OF_RATELIMIT_RETRIES,
  RATELIMIT_RETRY_PERIOD_MS,
  REPO_NOT_FOUND,
  USER_NOT_FOUND,
} from "../constants";
import { Authentication } from "@openqlabs/utils";
export * from "./queryFragments";
export * from "./fetchRateLimits";

export async function fetchGithubGraphql<ResponseType>(
  query: DocumentNode,
  responseSchema: z.Schema<ResponseType>,
  variables: Record<string, unknown>,
  authenticator: Authentication.Manager,
  prisma: PrismaClient | undefined,
  retries = NUMBER_OF_RATELIMIT_RETRIES,
  rateLimitRetryPeriod = RATELIMIT_RETRY_PERIOD_MS
): Promise<ResponseType> {
  checkGraphqlQuery(query);
  try {
    const response = await fetch("https://api.github.com/graphql", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authenticator.getAccessToken()}`,
      },
      body: JSON.stringify({
        query: print(query),
        variables,
      }),
    });

    type GithubGraphqlResponse =
      | {
          errors?: unknown[];
          data?: unknown;
        }
      | {
          message: string;
          documentation_url: string;
        };

    const resp: GithubGraphqlResponse =
      (await response.json()) as GithubGraphqlResponse;

    if ("message" in resp) {
      console.error(`${resp.message}: ${authenticator.getAccessToken()}`);
      throw new Error(`${resp.message}`);
    }

    const data = resp as {
      errors?: unknown[];
      data?: unknown;
    };

    if (data.errors) {
      console.error(
        authenticator.getAccessToken(),
        "GraphQL query failed",
        print(query),
        data.errors,
        variables
      );
      throw new Error(
        `GraphQL query failed: ${JSON.stringify(data.errors[0])}`
      );
    }

    if (!isQueryResponseOfType(responseSchema, data.data)) {
      console.error(print(query), data.data);
      throw new Error("Invalid response");
    }

    return data.data;
  } catch (error) {
    const errorMessage = errorToString(error as Error);
    console.log("Github Graphql Error message: ", errorMessage);
    if (errorMessage) await authenticator.setErrored(errorMessage);
    if (
      retries === 0 ||
      errorMessage?.includes(REPO_NOT_FOUND) ||
      errorMessage?.includes(USER_NOT_FOUND)
    ) {
      throw error;
    }

    if (retries === 0) {
      throw new Error(errorMessage ?? "");
    }

    console.error(error, `Retrying in ${rateLimitRetryPeriod / 1000} seconds`);
    const waitMilliseconds = rateLimitRetryPeriod;
    await sleep(waitMilliseconds);

    return await fetchGithubGraphql(
      query,
      responseSchema,
      variables,
      authenticator,
      prisma,
      retries - 1,
      rateLimitRetryPeriod * 2
    );
  }
}
