import { Authentication } from "@openqlabs/utils";
import { isGithubRateLimitsResponse } from "./types";
import { mockFullRateLimit } from "./mockFullRateLimitResponse";

export async function fetchRateLimits(authenticator: Authentication.Manager) {
  try {
    const response = await fetch("https://api.github.com/rate_limit", {
      headers: {
        Authorization: `Bearer ${authenticator.getAccessToken()}`,
      },
    });
    const data = await response.json();

    if (!isGithubRateLimitsResponse(data)) {
      return mockFullRateLimit.resources;
    }

    return data.resources;
  } catch (error) {
    console.error("Error fetching rate limits", error);
    return mockFullRateLimit.resources;
  }
}
