import {
  ExclamationTriangleIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/outline";

export default function Notification({
  children,
  className = "",
  info = false,
}: Readonly<{
  children: React.ReactNode;
  className?: string;
  info?: boolean;
}>) {
  return (
    <div
      className={`mb-6 flex w-fit items-center rounded-md bg-yellow-200 gap-2 p-2 text-sm text-yellow-800 ${className}`}
    >
      {info ? (
        <InformationCircleIcon className="inline-block h-6 ml-1" />
      ) : (
        <ExclamationTriangleIcon className="inline-block h-6 ml-1" />
      )}
      {children}
    </div>
  );
}
