import type { PrismaClient } from "@openqlabs/drm-db";
import { sleep } from "@openqlabs/utils";
import { ONE_MINUTE, SECONDARY_RATELIMIT_RETRY_PERIOD_MS } from "../constants";

export const setAccessTokenRateLimited = async (
  accessToken: string,
  prisma: PrismaClient
) => {
  await prisma.account.updateMany({
    where: { access_token: accessToken },
    data: {
      secondaryRateLimitTimestamp: new Date().getTime(),
    },
  });
};

export const setAccessTokenExpired = async (
  accessToken: string,
  prisma: PrismaClient
) => {
  await prisma.account.updateMany({
    where: { access_token: accessToken },
    data: {
      tokenDead: true,
    },
  });
};

export const getAccessTokenFromTeamAccountId = async (
  teamAccountId: string,
  prisma: PrismaClient
) => {
  const currentTimeInMs = new Date().getTime();
  const fiveMinAgo = currentTimeInMs - SECONDARY_RATELIMIT_RETRY_PERIOD_MS;
  const allAccounts = (
    await prisma.user.findMany({
      where: {
        teamAccountIds: { has: teamAccountId },
      },
      include: {
        accounts: {
          where: {
            provider: "github",
          },
        },
      },
    })
  )
    .map((u) => u.accounts)
    .flat();

  const accounts = allAccounts.filter(
    (account) =>
      account.tokenDead === false &&
      account.secondaryRateLimitTimestamp < fiveMinAgo
  );

  if (accounts.length === 0) {
    const allTokenDead = allAccounts.every((account) => account.tokenDead);
    if (allTokenDead) {
      throw new Error(
        "All team tokens are expired for teamAccountId: " + teamAccountId
      );
    }
  }

  const account = accounts[Math.floor(Math.random() * accounts.length)];

  const accessToken = account?.access_token;

  return accessToken;
};

export class Manager {
  accessToken: string;
  teamAccountId: string;
  prisma: PrismaClient | undefined;

  /**
   * Once again, we set the `constructor` to be private.
   * This ensures that all consumers of this class will use
   * the factory function as the entry point.
   */
  private constructor(
    teamAccountId: string,
    accessToken: string,
    prisma?: PrismaClient
  ) {
    this.accessToken = accessToken;
    this.teamAccountId = teamAccountId;
    this.prisma = prisma;
  }

  getAccessToken = () => this.accessToken;
  setAccessToken = async () => {
    if (this.prisma) {
      const newAccessToken = await getAccessTokenFromTeamAccountId(
        this.teamAccountId,
        this.prisma
      );
      if (newAccessToken) {
        this.accessToken = newAccessToken;
      } else {
        console.log("waiting for new access token");
        await sleep(SECONDARY_RATELIMIT_RETRY_PERIOD_MS);
        await this.setAccessToken();
      }
    }
  };
  setErrored = async (errorCode: string) => {
    if (this.prisma) {
      if (errorCode.includes("You have exceeded a secondary rate limit")) {
        await setAccessTokenRateLimited(this.accessToken, this.prisma);
        console.log(errorCode, "setting rate limited");
        await this.setAccessToken();
      } else if (errorCode.includes("Bad credentials")) {
        await setAccessTokenExpired(this.accessToken, this.prisma);
        console.log(errorCode, "setting token expired");
        await this.setAccessToken();
      } else {
        return "error not handled";
      }
    }
  };

  /**
   * Exchanges the authorization code for an access token.
   * @param code - The authorization code from Spotify.
   */
  static async initialize(
    teamAccountId: string,
    prisma: PrismaClient | undefined,
    frontendAccessToken?: string
  ): Promise<Manager> {
    let accessToken = "";
    if (frontendAccessToken) {
      accessToken = frontendAccessToken;
    }
    if (!accessToken && prisma) {
      const newAccessToken = await getAccessTokenFromTeamAccountId(
        teamAccountId,
        prisma
      );
      if (!newAccessToken) {
        await sleep(ONE_MINUTE);
        return Manager.initialize(teamAccountId, prisma);
      } else {
        accessToken = newAccessToken;
      }
    }
    return new Manager(teamAccountId, accessToken, prisma);
  }
}
